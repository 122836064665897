import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {
      isLock: false,
      statData: {},
      isLoading: false,
      isKeyLoading: false,
      isTapLoading: false,
      stepLoading: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false
      },
      stepKeyLoading: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
      },
      stepTapLoading: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
      }
    };
  },
  methods: {
    onLock() {
      this.isLock = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        this.$router.push("/quest");
      }, 2000);
    },
    async getData(step) {
      this.isLoading = true;
      await this.getStepData(step);
      if (step + 1 <= 9) {
        this.getData(step + 1);
      } else {
        this.isLoading = false;
      }
    },
    async getKeyData(step) {
      this.isKeyLoading = true;
      await this.getStepKeyData(step);
      if (step + 1 <= 5) {
        this.getKeyData(step + 1);
      } else {
        this.isKeyLoading = false;
      }
    },
    async getTapData(step) {
      this.isTapLoading = true;
      await this.getStepTapAirDropData(step);
      if (step + 1 <= 5) {
        this.getTapData(step + 1);
      } else {
        this.isTapLoading = false;
      }
    },
    async getStepData(step) {
      try {
        this.stepLoading[step] = true;
        let rs = await this.$http.post("/stat", {
          step: step || 0
        });
        this.stepLoading[step] = false;
        if (rs.code == 0) {
          this.statData = {
            ...this.statData,
            ...rs.data
          };
        }
      } catch (e) {}
    },
    async getStepKeyData(step) {
      try {
        this.stepKeyLoading[step] = true;
        let rs = await this.$http.post("/stat", {
          type: "key",
          step: step || 0
        });
        this.stepKeyLoading[step] = false;
        if (rs.code == 0) {
          this.statData = {
            ...this.statData,
            ...rs.data
          };
        }
      } catch (e) {}
    },
    async getStepTapAirDropData(step) {
      try {
        this.stepTapLoading[step] = true;
        let rs = await this.$http.post("/stat", {
          type: "airdroptap",
          step: step || 0
        });
        this.stepTapLoading[step] = false;
        if (rs.code == 0) {
          this.statData = {
            ...this.statData,
            ...rs.data
          };
        }
      } catch (e) {}
    },
    onRefresh() {
      this.getData(0);
    },
    onKeyRefresh() {
      this.getKeyData(0);
    },
    onTapRefresh(step = 0) {
      this.getTapData(step);
    }
  },
  mounted() {
    this.getData(0);
  }
};